import {useEffect, useState} from 'react';

export default function Header({lang = 'en', changeLangEn, changeLangEs}) {
    const desktopBG = process.env.PUBLIC_URL + '/assets/header-desktop.jpg';
    const mobileBG = process.env.PUBLIC_URL + '/assets/header-desktop.jpg';

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const imageUrl = windowWidth >= 799 ? desktopBG : mobileBG;

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        <header className="banner" style={{backgroundImage: `url(${imageUrl})`}}>
            <div className="container">
                <div className="col">
                    <h1 className="banner__title">F.C. Negreiralona</h1>

                    {lang === 'es' &&
                        <h2 className="banner__sub-title">
                            La verdad <span>oculta</span> detrás del <strong>relato</strong>
                        </h2>
                    }
                    {lang === 'en' &&
                        <h2 className="banner__sub-title">
                            The truth <span>behind</span> the <strong>made up</strong> story
                        </h2>
                    }

                    {lang === 'es' &&
                        <p className="banner__text">
                            Durante casi un siglo se ha tergiversado la historia del fútbol español, generando un relato
                            falaz alrededor del F.C. Barcelona. Aquí se recogen documentos tangibles y fehacientes que
                            desmontan con hechos la mentira creada con palabras.
                        </p>
                    }
                    {lang === 'en' &&
                        <p className="banner__text">
                            For almost a century the history of Spanish football has been distorted, generating a
                            fallacious story around F.C. Barcelona. Here we collect tangible and reliable documents that
                            dismantle with facts the lie created with words.
                        </p>
                    }

                    <div className="lang-btn">
                        <button className="neon" onClick={changeLangEn}><span>ENGLISH</span><i></i></button>
                        <button className="neon" onClick={changeLangEs}><span>ESPAÑOL</span><i></i></button>
                    </div>
                </div>
            </div>
        </header>
    );
}
