import './Nav.css';
import {faFilter, faXmark} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useRef} from 'react';

export default function NavTagFilter({lang = 'en', news, allTags, lastTags, tagHandler}) {
    const navRef = useRef(null);
    const navCloseRef = useRef(null);

    const lastTagsUsed = lastTags.split(',');
    let currentActiveChecks = [...lastTagsUsed];

    /**
     * Returns the status of a checkbox (true/false)
     * @param tag ID of checkbox
     * @return {boolean}
     */
    function isChecked(tag) {
        return currentActiveChecks.includes(tag) === true;
    }

    /**
     * Action on check change status
     * @param event
     */
    function changeTagCheck(event) {
        const tag = event.target.value;
        if (event.target.checked) {
            if (!currentActiveChecks.includes(tag)) {
                currentActiveChecks.push(tag);
            }
        } else {
            const index = currentActiveChecks.indexOf(tag);
            currentActiveChecks.splice(index, 1);
        }

        // Return array ordered without elements
        const aux = allTags.reduce(function (result, element) {
            if (currentActiveChecks.includes(element)) {
                result.push(element);
            }
            return result;
        }, []);
        tagHandler(aux);
    }

    /**
     * Change the visibility of filters panel
     * @param event
     */
    function toggleFilters(event) {
        if (event.currentTarget.dataset.show === 'true') {
            navRef.current.classList.add('active');
            navCloseRef.current.classList.remove('hide');
        } else {
            navRef.current.classList.remove('active');
            navCloseRef.current.classList.add('hide');
        }
    }

    return (
        <nav ref={navRef}>
            <div className="burger-container">
                <button className="btn-95 tooltip" onClick={toggleFilters} data-show="true">
                    <FontAwesomeIcon icon={faFilter}/>
                    {lang === 'en' &&
                        <span className="tooltiptext">Filter content</span>
                    }
                    {lang === 'es' &&
                        <span className="tooltiptext">Filtrar contenido</span>
                    }
                </button>
            </div>

            <ul>
                <li className="hide close" ref={navCloseRef}>
                    <button onClick={toggleFilters} data-show="false"><FontAwesomeIcon
                        icon={faXmark}/>
                    </button>
                </li>
                {allTags.map((t, idx) => {
                    return (
                        <li key={idx}>
                            <div className="check-nav">
                                <input id={'tag-' + idx} type="checkbox" defaultChecked={isChecked(t)}
                                       onChange={changeTagCheck}
                                       value={t}/>
                                <label htmlFor={'tag-' + idx}>
                                    <p><span>#</span>{t}</p>
                                </label>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
}
