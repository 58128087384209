import './Block.css';
import Tag from '../nav/Tag';
import BlockBody from './BlockBody';
import Month from './Month';

export default function Block({data, lang = 'en'}) {

    return (
        <div className="timeline__block">
            {!data.separator &&
                <div className="timeline__block__bullet-point">
                    <span className="timeline__block__circle"></span>
                </div>
            }
            {!data.separator &&
                <div className="timeline__block__head">
                    <h2 className="timeline__block__title">
                        <Month month={data.month} lang={lang}/>&nbsp;{data.year}
                    </h2>
                </div>
            }
            {!data.separator &&
                <div className="timeline__block__body">
                    <h3 id={data.navId}>{data.title} <Tag tags={data.tags}/></h3>
                    <p className="timeline__block__subtitle">{data.subtitle}</p>
                    <BlockBody lang={lang} body={data.body}/>
                </div>
            }
            {data.separator &&
                <div className="timeline__block__body">
                    <h1 className="separator" id={data.navId}>
                        - {data.year} -
                    </h1>
                </div>
            }
        </div>
    );
}
