const colors = {
    'Franco': 'grey',
    'Barsagate': 'blue',
    'Negreira': 'red',
    'VARMediapro': 'green',
    'Villarato': 'yellow',
    'DiStefano': 'indigo',
    'CasoSoule': 'purple',
    'BarsaStudios': 'pink'
};

export default function Tag({tags}) {
    return (
        <span>
            {tags.map((tag, index) => (
                <span className={'tag ' + colors[tag]} key={index}>#{tag}</span>
            ))}
        </span>
    );
}
