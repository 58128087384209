import './App.css';
import './Button.css';
import {faTiktok, faXTwitter, faYoutube} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Body from './Body';
import Header from './Header';
import {createStore} from 'state-pool';
import NavTagFilter from './nav/NavTagFilter';
import {Helmet} from "react-helmet";
// NEWS
import nEs2023 from './news/es/2023.json';
import nEn2023 from './news/en/2023.json';

const store = createStore();
let memStore = {};
store.persist({
    saveState: function (key, value = '') {
        // localStorage.setItem(key, value);
        memStore[key] = value;
    },
    loadState: function (key, noState) {
        // let aux = localStorage.getItem(key);
        let aux = memStore[key];
        return !aux ? noState : aux;
    },
    removeState: function (key) {
        // localStorage.removeItem(key);
        delete memStore[key];
    },
    clear: function () {
        // localStorage.clear();
        memStore = {};
    }
});

const App = () => {

    const tempL = navigator.language.split('-');
    const [language, setLanguage] = store.useState('language', {
        default: tempL[0] ?? 'en',
        persist: true
    });

    // NEWS
    let allNews = [];
    if (language === 'es') {
        allNews = [...nEs2023];
    } else {
        allNews = [...nEn2023];
    }

    // List of all existing tags
    const auxT = allNews.reduce(function (result, element) {
        if (element.tags) {
            element.tags.forEach(tag => {
                result.add(tag);
            });
        }
        return result;
    }, new Set());
    const allTags = [...auxT];

    const [tags, setTags] = store.useState('tags', {
        default: allTags.join(','),
        persist: true
    });

    const changeLangEn = () => {
        setLanguage('en');
    };
    const changeLangEs = () => {
        setLanguage('es');
    };

    const tagFilterChanged = (currentTagList) => {
        setTags(currentTagList.join(','));
    };

    const open = (what) => {
        let url = '';
        switch (what) {
            case 'youtube':
                url = 'https://www.youtube.com/@fcNegreiralona';
                break;
            case 'twitter':
                url = 'https://twitter.com/fcNegreiralona';
                break;
            case 'tiktok':
                url = 'https://www.tiktok.com/@fcnegreiralona';
                break;
            default:
                url = '/';
        }
        window.open(url, '_blank');
    };

    return (
        <div className="app">
            <Helmet htmlAttributes={{ lang : language }}/>

            <Header lang={language} changeLangEn={changeLangEn} changeLangEs={changeLangEs}></Header>

            <section className="timeline">
                <NavTagFilter lang={language} news={allNews} allTags={allTags} lastTags={tags}
                              tagHandler={tagFilterChanged}></NavTagFilter>
                <div className="container">
                    <Body lang={language} news={allNews} tags={tags}></Body>
                </div>
            </section>

            <footer className="footer">
                {/*<button className="btn-95"><FontAwesomeIcon icon={faYoutube}/></button>
                <button className="btn-95"><FontAwesomeIcon icon={faXTwitter}/></button>
                <button className="btn-95"><FontAwesomeIcon icon={faTiktok}/></button>*/}
            </footer>
        </div>
    );
};

export default App;
