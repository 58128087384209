import './BlockBody.css';
import {faVideo, faVolumeHigh} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import BigPicture from 'bigpicture';
import Markdown from 'react-markdown';
import myRemarkPlugin from './myRemarkPlugin';
import rehypeExternalLinks from 'rehype-external-links';
import {hideScroll, showScroll} from 'hide-show-scroll';
import remarkDirective from 'remark-directive';
import remarkGfm from 'remark-gfm';

export default function BlockBody({lang = 'en', body}) {
    let blockList = [];

    function galleryClick(event) {
        BigPicture({
            el: event.target,
            gallery: JSON.parse(event.target.dataset.images),
            loop: false,
            position: parseInt(event.target.dataset.position) ?? 0,
            overlayColor: 'rgba(0, 0, 0, .9)',
            animationStart: hideScroll,
            onClose: showScroll
        });
    }

    function imageClick(event) {
        BigPicture({
            el: event.target,
            imgSrc: event.target.src,
            overlayColor: 'rgba(0, 0, 0, .9)',
            animationStart: hideScroll,
            onClose: showScroll
        });
    }

    function youtubeClick(event) {
        BigPicture({
            el: event.target,
            ytSrc: event.target.dataset.videoid,
            ytNoCookie: true,
            overlayColor: 'rgba(0, 0, 0, .9)',
            animationStart: hideScroll,
            onClose: showScroll
        });
    }

    function vimeoClick(event) {
        BigPicture({
            el: event.target,
            vimeoSrc: event.target.dataset.videoid,
            overlayColor: 'rgba(0, 0, 0, .9)',
            animationStart: hideScroll,
            onClose: showScroll
        });
    }

    function audioClick(event) {
        BigPicture({
            el: event.target,
            audio: event.target.dataset.audiosrc,
            overlayColor: 'rgba(0, 0, 0, .9)',
            animationStart: hideScroll,
            onClose: showScroll
        });
    }

    body.forEach((el, idx) => {
        switch (el.type) {
            case 'text':
                const txtS = lang === 'es' ? 'Fuente' : 'Source';
                blockList.push(
                    <div key={idx} className="timeline__block__text body-block">
                        <Markdown
                            remarkPlugins={[remarkGfm, remarkDirective, myRemarkPlugin]}
                            rehypePlugins={[[rehypeExternalLinks, {
                                target: '_blank',
                                rel: ['noopener noreferrer']
                            }]]}>
                            {el.text}
                        </Markdown>
                        {(el.source && el.source !== '') &&
                            <p className="source bigger">- {txtS} <a
                                rel="noopener noreferrer"
                                target="_blank"
                                href={el.sourceUrl}>{el.source}</a> -</p>
                        }
                    </div>
                );
                break;

            case 'image':
                blockList.push(
                    <div key={idx} className="timeline__block__text body-block body-image">
                        <img src={process.env.PUBLIC_URL + el.src} onClick={imageClick} data-caption={el.caption}
                             data-source={process.env.PUBLIC_URL + el.src} alt={el.caption}/>
                        {el.caption &&
                            <p className="body-caption">
                                {el.caption}
                                {(el.source && el.source !== '') &&
                                    <span className="source"> - <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href={el.sourceUrl}>{el.source}</a></span>
                                }
                            </p>
                        }
                    </div>
                );
                break;

            case 'gallery':
                let list = [];
                el.images.forEach(img => {
                    let aux = JSON.parse(JSON.stringify(img));
                    aux.src = process.env.PUBLIC_URL + aux.src;
                    list.push(aux);
                });

                blockList.push(
                    <div key={idx} className="timeline__block__text body-block">
                        <div className="stack stack-sideslide">
                            <img src={process.env.PUBLIC_URL + list[0].src} onClick={galleryClick}
                                 data-images={JSON.stringify(list)} data-position="0" alt=""/>
                            {list.length > 1 &&
                                <img src={process.env.PUBLIC_URL + list[1].src} onClick={galleryClick}
                                     className="hide-small-screen"
                                     data-images={JSON.stringify(list)} data-position="1" alt=""/>
                            }
                            {list.length > 2 &&
                                <img src={process.env.PUBLIC_URL + list[2].src} onClick={galleryClick}
                                     className="hide-mid-screen"
                                     data-images={JSON.stringify(list)} data-position="2" alt=""/>
                            }
                        </div>
                        {(el.footer && el.footer !== '') &&
                            <p className="stack-footer">
                                {el.footer}
                                {(el.source && el.source !== '') &&
                                    <span className="source"> - <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href={el.sourceUrl}>{el.source}</a></span>
                                }
                            </p>
                        }
                    </div>
                );
                break;

            // Get thumb -> https://www.softr.io/tools/download-youtube-thumbnail
            case 'youtube':
                blockList.push(
                    <div key={idx} className="timeline__block__text body-block body-video">
                        <img src={process.env.PUBLIC_URL + el.thumb} onClick={youtubeClick}
                             data-videoid={el.videoId} alt={el.caption}/>
                        <FontAwesomeIcon icon={faVideo}/>
                        {el.caption &&
                            <p className="body-caption">
                                {el.caption}
                                {(el.source && el.source !== '') &&
                                    <span className="source"> - <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href={el.sourceUrl}>{el.source}</a></span>
                                }
                            </p>
                        }
                    </div>
                );
                break;

            case 'vimeo':
                blockList.push(
                    <div key={idx} className="timeline__block__text body-block body-video">
                        <img src={process.env.PUBLIC_URL + el.thumb} onClick={vimeoClick}
                             data-videoid={el.videoId} alt={el.caption}/>
                        <FontAwesomeIcon icon={faVideo}/>
                        {el.caption &&
                            <p className="body-caption">
                                {el.caption}
                                {(el.source && el.source !== '') &&
                                    <span className="source"> - <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href={el.sourceUrl}>{el.source}</a></span>
                                }
                            </p>
                        }
                    </div>
                );
                break;

            case 'audio':
                let img = el.thumb ? el.thumb : '/assets/audio.png';
                let css = el.thumb ? '' : 'small';

                blockList.push(
                    <div key={idx} className="timeline__block__text body-block body-audio">
                        <img className={css} src={process.env.PUBLIC_URL + img} onClick={audioClick}
                             data-audiosrc={process.env.PUBLIC_URL + el.src} alt={el.caption}/>
                        {css === '' &&
                            <FontAwesomeIcon icon={faVolumeHigh}/>
                        }
                        {el.caption &&
                            <p className="body-caption">
                                {el.caption}
                                {(el.source && el.source !== '') &&
                                    <span className="source"> - <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href={el.sourceUrl}>{el.source}</a></span>
                                }
                            </p>
                        }
                    </div>
                );
                break;
        }
    });


    return (
        <div>{blockList}</div>
    );
}
