import Block from './blocks/Block';

const includesAny = (arr, values) => values.some(v => arr.includes(v));

export default function Body({lang = 'en', news, tags = ''}) {
    // Load news
    let filteredNews = [...news];

    let tagsToShow = tags.split(',');
    if (tagsToShow.length > 0) {
        const aux = news.reduce(function (result, element) {
            if (element.tags && includesAny(tagsToShow, element.tags)) {
                result.push(element);
            }

            // For elements that do not have tags (they are always shown)
            if (!element.tags) {
                result.push(element);
            }
            return result;
        }, []);

        filteredNews = [...aux];
    }

    return (
        <div className="timeline__wrapper">
            <div className="timeline__progressbar"></div>
            {filteredNews.map((row, index) => (
                <Block data={row} lang={lang} key={index}/>
            ))}
            <div className="timeline__hider"></div>
        </div>
    );
}
