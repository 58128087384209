export default function Month({month, lang='en', long = true}) {
    const ll = long ? 'long' : 'short';
    const txt = {
        'en': {
            'short': ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DEC'],
            'long': ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        },
        'es': {
            'short': ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC'],
            'long': ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
        }
    };

    let num, final;
    try {
        num = parseInt(month);
        final = txt[lang][ll][num-1];
    } catch (e) {
        final = '--';
    }

    return (
        <span>{final}</span>
    );
}
